import { Suitcase } from '@styled-icons/remix-line';
import { Suitcase as SuitcaseFill } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function SuitcaseIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Suitcase />
    </Icon>
  );
}

export function SuitcaseFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <SuitcaseFill />
    </Icon>
  );
}
